
.list-wrapper {
	width: 25%;
	min-width: 272px;
    padding: 0 8px;
    height: 100%;
    border: none;
    box-sizing: border-box;
    border-radius: 9px;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    background: transparent;
}
.list-header {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 0;
}
.list-content {
	height: 100%;
    border-radius: 4px;
    border-bottom: none;
    border-right: none;
    border-left: none;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    white-space: normal;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, .1);
    z-index: 0;
}

.list-cards {
    border: none;
	flex: 1 1 auto;
    margin-bottom: 0;
	overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    min-height: 0;
}