.container-tools {
    width: 100%;
    height: 0px;
    position: fixed;
    z-index: 1038;
}
.tools {
    position: fixed;
    top: 0px;
    right: 0px;
    text-align: center;
    z-index: 1039;
    transition: all .5s;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.tickets-container {
    font-size: 14px;
    overflow: hidden;
    transition: all .25s;
}

.navigation li a {
    padding: 10px 0px;
}

.item-tool {
    min-width: 30px !important;
    border-radius: 0px;
    transition: all .5s;
}

.item-tool:hover {
    background-color: #367fa9;
}

.item-tool >label {
    vertical-align: super;
    cursor: pointer;
    font-size: 18px;
}

.item-container {
    height: auto;
    width: 270px;
    padding: 0px;
}

.list-container {
    margin-bottom: 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0px;
}

.list-container>* {
    cursor: pointer;
}
.list-container>:first-child {
    border: none;
    border-radius: 0px;
}

.item-container>:last-child {
    padding: 0px;
    border-radius: 5px;
    overflow: hidden;
    border-bottom: 0px;
}

.list-container>*:hover {
    background-color: #f5f5f5;
    color: #555;
    text-decoration: none;
}

.list-container div[class*='col-'] {
    padding-left: 0px;
    padding-right: 0px;
}

.list-container div[class*='row'] {
    margin-left: 0px;
    margin-right: 0px;
}

.list-container *[class*='list-group-item'] {
    padding: 0px;
}

.tab-title-animated {
  animation: blinker 1s infinite;
}

.close-button {
    padding: 0px;
    min-width: 10px;
    padding-left: 0px;
    padding-right: 0px;
    background: transparent;
    width: 100%;
    height: 100%;
    border: none;
}

.pointer {
    cursor: pointer;
    display: block;
    height: 100%;
    vertical-align: middle;
    margin: 0px;
    text-align: center;
}

.pointer >* {
    display: block;
}

.pointer:after {
  content: "";
  display: inline-block;
  height: 100%;
}

.close-button:focus {
    outline: none;
}

.expanded {
    height: 100%;
    overflow: hidden;
    transition: all .5s;
}

.collapsed {
    height: 0px;
    overflow: hidden;
    transition: all .5s;
}

.hr-text {
  position: relative;
  margin-bottom: 0.5em;
}

.hr-text:before {
  position: absolute;
  top: 100%;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #383838
}

@keyframes blinker { 
  0%   { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}