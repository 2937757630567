.color-palette {
    height: 35px;
    line-height: 35px;
    text-align: center;
}

.color-palette-set {
    margin-bottom: 15px;
}

.color-palette span {
    display: none;
    font-size: 12px;
}

.color-palette:hover span {
    display: block;
}

.color-palette-box h4 {
    position: absolute;
    top: 100%;
    left: 25px;
    margin-top: -40px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    display: block;
    z-index: 7;
}