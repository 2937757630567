.small {
  font-size: 12px;
}

.chat-container {
    position: relative;
    transition: .5s ease-in-out;
    text-align: left;
    white-space: normal;
    border-radius: 0;
    padding: 2px 0px;
    box-shadow: inset 0px 0px 1px 2px rgba(0, 0, 0, 0.6);
}

.chat-container >:first-child {
    margin: 0px;
    border-radius: 0;
}

.chat-container div[class*='panel-footer'] {
    padding: 0px;
}


.chat-container div[class*='panel-footer'] button {
    border-radius: 0;
}

.chat-container div[class*='panel-heading'] button {
    max-height: 50px;
}

.chat-container textarea {
    width: 100% !important;
    max-height: calc(1.5*7em + 12px);
    min-height: calc(1.5*2em + 12px);
}

.overlayBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(55, 33, 33, 0.90);
    border-radius: 3px;
    z-index: 99;
}

.overlayBlock >* {
	text-align: center;
	color: white; 
	font-weight: 200px;
}

.chatMessage {
  word-wrap: break-word;
  display: flex;
  margin-top: 8px;
}

.chatMessageContent {
  word-wrap: break-word;
  width: 75%;
  padding: 8px 10px;
  border-radius: 3px;
  background: rgba(3, 105, 180, 0.9);
  color: #fff;
}
.chatMessage.leftAligned .chatMessageContent {
  background: rgba(1, 174, 173, 0.9);
}
.chatMessageFooter {
  margin: 0;
}
.chatMessageFooter > * + * {
  margin-left: 5px;
}

.chat-message-image {
  display: inline-block;
  position: relative;
}

.chat-message-image>* {
  transition: all .35s;
}

.overlayd-page {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: block;
  position: fixed;
  background: rgba(34,45,50,.5);
  z-index: 100000;
}

.overlayd-page .closed-button {
  position: fixed;
  right: 10vw;
  top: 10vh;
  width: 5vw;
  cursor: pointer;
}

.chat-message-image>img {
  padding: 2px;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.chat-message-img-open {
  width: auto;
  opacity: 1;
}
.chat-message-img {
  cursor: default!important;
  position: fixed;
  max-height: 80vh;
  max-width: 80vw;
  z-index: 100001;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
}