
.merchantId > * {
	margin-top: 5px;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    background: #fff !important;
}

.merchantId {
    border: 0px !important;
}