.overlayBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(55, 33, 33, 0.90);
    border-radius: 3px;
    z-index: 99;
}

.overlayBlock >* {
	text-align: center;
	color: white; 
	font-weight: 200px;
}

.chatMessageContent {
  padding: 15px;
  border-radius: 3px;
  background: #0369b4;
  color: #fff;
}
.chatMessage.leftAligned .chatMessageContent {
  background: #01aead;
}
.chatMessageFooter {
  margin: 0;
}
.chatMessageFooter > * + * {
  margin-left: 5px;
}
.chatMessage.leftAligned .chatMessageFooter {
  text-align: right;
}

