html,
html body {
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: #ecf0f5;
	font-size: 0.9rem;
}

#root {
	background-color: #222d32;
	height: 100%;
	/* zoom: 0.9; */
}

#root .wrapper {
	/* height: 100%; */
	display: flex;
	flex-direction: column;
}

#root .wrapper .content-wrapper {
	min-height: calc(111vh - 70px);
	height: 100%;
	flex-grow: 1;
}

#root nav.main-header {
	background: #ff3232;
	margin: 0;
	height: 70px;
}

#root nav.main-header > .container-fluid {
	margin: 0;
	padding-right: 0;
}

#root nav.main-header.navbar-default .navbar-toggle {
	position: absolute;
	top: 10px;
	left: 10px;
	border-color: #181f23;
	background-color: #181f23;
}

#root nav.main-header.navbar-default .navbar-toggle > span {
	background-color: #fff6de;
}

#root nav.main-header .topMenu {
	display: none;
}

#root nav.main-header .navbar-collapse {
	background-color: #ff3232;
}

#root nav.main-header li.clickable > a:hover {
	background-color: #ef0000;
	color: #fff;
	cursor: pointer;
}

#root .navbar-default .navbar-nav > .active > a {
	background-image: linear-gradient(to bottom, #ef0000 0, #ef0000 100%);
	background-color: #ef0000;
	color: #fff;
	cursor: pointer;
}

#root nav.main-header a {
	color: #fff;
	line-height: 39px;
	cursor: default;
}

#root nav.main-header a.logo {
	background-color: #222d32;
	height: 70px;
	line-height: 70px;
	cursor: pointer;
}

#root nav.main-header a.logo > span.logo-lg > img {
	max-width: 70%;
}

#root nav.main-header a.logo span.motto {
	display: block;
	font-size: 14px;
	padding: 0;
	margin: 0;
	line-height: 14px;
	font-family: sans-serif;
}

#root nav.main-header li.toggle a {
	font-family: fontAwesome, sans-serif;
}

#root .sidebar {
	padding: 0px;
}

#root aside.main-sidebar {
	padding-top: 70px;
	max-height: calc(111vh - 70px);
	overflow-y: auto;
}

#root aside.main-sidebar .sidebar .user-panel {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	color: #b8c7ce;
}

#root aside.main-sidebar .sidebar .avatar-holder {
	color: #fff;
	text-transform: uppercase;
	padding: 10px;
	border: solid 1px #fff;
	border-radius: 50%;
}

#root aside.main-sidebar .sidebar .sidebar-menu {
	margin-top: 20px;
}

#root aside.main-sidebar .sidebar .sidebar-menu li a {
	font-size: 15px;
	white-space: nowrap;
}

#root aside.main-sidebar .sidebar .sidebar-menu li a img {
	width: 20px;
	padding-right: 5px;
}

#root aside.main-sidebar .sidebar .sidebar-menu li.treeview > a > i.fa.fa-angle-left {
	color: #ff3232;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	position: relative;
}

#root .Select {
	display: inline-block;
	vertical-align: middle;
	min-width: 100px;
	cursor: pointer;
	max-width: 100%;
	width: 100%;
}

#root .Select.smallOne {
	width: 150px !important;
	border: none !important;
}

#root .Select .Select-arrow {
	border-color: #ff3232 transparent transparent;
}

#root .Select .Select-value-label {
	color: #ff3232;
	font-weight: bold;
}

#root .Select .Select-control {
	border: none;
	border-radius: 0;
	background: none;
}

#root .Select .Select-control:hover {
	-webkit-box-shadow: 0 0 0 0 transparent;
	-moz-box-shadow: 0 0 0 0 transparent;
	box-shadow: 0 0 0 0 transparent;
}

#root .direct-chat-contacts.direct-chat-contacts-open {
	transform: translate(0, 0);
}

#root div.wrapper {
	overflow-y: auto;
}

#root div.wrapper h1 i.fa {
	margin-right: 20px;
}

#root div.wrapper .info-box-content {
	white-space: nowrap;
	overflow: hidden;
}

#root .offers.content {
	padding-bottom: 0;
	overflow: hidden;
}

#root .offers .info-box .info-box-text {
	font-weight: bold;
}

#root .offers .info-box .info-box-icon {
	position: relative;
	min-height: 135px;
	width: 130px;
}

#root .offers .info-box .info-box-icon .dummyPadding {
	padding-top: 100%;
}

#root .offers .info-box .info-box-icon .img-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	font: 0/0 a;
}

#root .offers .info-box .info-box-icon .img-container:before {
	content: ' ';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

#root .offers .info-box .info-box-icon .img-container img {
	vertical-align: middle;
	display: inline-block;
	max-width: 80%;
	max-height: 80%;
}

#root .offers .info-box .col-md-6.col-sm-6.col-xs-6 {
	overflow: hidden;
}

#root .offers .info-box .countried {
	display: block;
}

#root .offers .info-box .countried img {
	width: 20px;
	margin: 5px;
}

#root .offers .info-box .row {
	margin-top: 5px;
}

#root .offers .info-box .row .col-md-6:first-child {
	font-weight: bold;
}

#root section.content {
	overflow: auto;
}

html body .modal-content {
	border-radius: 3px;
	border: solid 1px #ff3232;
	box-shadow: 0 5px 15px rgba(96, 236, 133, 0.19);
}

html body .modal-content .modal-header {
	background: #ff3232;
	color: #ffffff;
}

html body .modal-content .modal-header h4.modal-title {
	display: inline-block;
}

html body .modal-content .modal-header .closeButton {
	float: right;
	display: inline-block;
	color: #ffffff;
}

html body .modal-content .modal-body {
	padding-bottom: 0;
	padding-top: 30px;
}

html body .modal-content .modal-body form .input-group {
	width: 50%;
	margin: 0 auto 10px auto;
}

html body .modal-content .modal-body form .input-group.invalidTrigger {
	animation: invalidityAnimation 0.2s;
	-webkit-animation: invalidityAnimation 0.2s;
	border: solid 1px #ff0000;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(233, 102, 102, 0.6);
}

html body .modal-content .modal-body form .input-group input.form-control.invalid {
	border: solid 1px #ff0000;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(233, 102, 102, 0.6);
}

html body .modal-content .modal-body form .input-group input.form-control.valid {
	border: solid 1px #ff3232;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 233, 142, 0.6);
}

html body .modal-content .modal-footer {
	border-top: none;
}

html body div.centered {
	text-align: center;
}

html body a {
	text-decoration: none !important;
	white-space: normal;
}

html body .main-alert {
	position: fixed;
	top: 0;
	left: 30%;
	width: 40%;
	z-index: 9999;
	border-radius: 0;
	min-height: 50px;
}

html body .main-alert.alert-danger {
	background: #ff0000;
	border: solid 1px #ff0000;
}

html body .main-alert.alert-success {
	background: #ff3232;
	border: solid 1px #ff3232;
}

@keyframes invalidityAnimation {
	from {
		left: -20px;
	}

	to {
		left: 20px;
	}
}

@-webkit-keyframes invalidityAnimation {
	from {
		left: -20px;
	}

	to {
		left: 20px;
	}
}

.sidebar-mini.sidebar-collapse .user-panel {
	display: none;
}

.sidebar-mini.sidebar-collapse .col-md-6.col-sm-6.col-xs-6 {
	overflow: hidden;
}

.sidebar-mini.sidebar-collapse .info-box:hover .col-md-6.col-sm-6.col-xs-6 {
	width: 100%;
	transition-duration: 1s;
}

@media (max-width: 768px) {
	.info-box:hover,
	.info-box:focus {
		-webkit-box-shadow: 0 0 5px #ff3232;
		-moz-box-shadow: 0 0 5px #ff3232;
		box-shadow: 0 0 5px #ff3232;
	}

	.info-box:hover .col-md-6.col-sm-6.col-xs-6,
	.info-box:focus .col-md-6.col-sm-6.col-xs-6 {
		width: 100%;
		transition-duration: 0.75s;
		white-space: normal;
	}

	#root nav.main-header .topMenu {
		display: block;
	}

	#root nav.main-header .mediaHidden {
		display: none !important;
	}

	#root nav.main-header .navbar-collapse ul.nav:not(.inline),
	#root nav.main-header .navbar-collapse ul.nav:not(.inline) > li {
		display: block;
		width: 100%;
	}
}

.widget-user .widget-user-image > img {
	background: rgba(255, 255, 255, 0.5);
	-webkit-box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
	-moz-box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
	box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
	max-height: 100px;
	max-width: 100px;
	width: auto !important;
}

.description-header > img {
	width: 25px;
	border: solid 1px #333;
	margin-right: 10px;
}

.box-widget:hover {
	box-shadow: 2px 3px 7px rgba(255, 0, 0, 0.2);
	-webkit-transition-duration: 0.115s;
	-moz-transition-duration: 0.115s;
	-ms-transition-duration: 0.115s;
	-o-transition-duration: 0.115s;
	transition-duration: 0.115s;
}

.box-widget .box-footer:not(.noHover):hover {
	background-color: rgba(0, 167, 208, 0.4);
}

.box-widget i.lockIcon.fa {
	float: right;
	font-size: 28px;
}

.box-widget i.lockIcon.fa.fa-lock {
	color: #ff3232;
	cursor: pointer;
}

.box-widget h3.widget-user-username.centered {
	text-align: center;
}

.box-widget.widget-user .widget-user-image {
	top: 50px;
}

.land-item > div {
	background-color: #fff;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.land-item > div:hover {
	background: #9ec1e4;
	cursor: pointer;
}

.land-item.selected > div {
	background: #9ec1e4;
}

.land-item.landings {
	margin-top: 10px;
}

.land-item.landings div.land-img {
	max-width: 100%;
	height: 150px;
	padding: 15px;
}

.land-item.landings div.land-img > img {
	max-height: 100%;
	max-width: 100%;
}

.land-item .land-img > img {
	max-width: 50px;
}

.wBlock.offerTile .col-sm-2 > i.fa {
	font-size: 28px;
}

.wBlock.offerTile .col-sm-2 > i.fa.fa-lock {
	color: #ff3232;
}

.wBlock.offerTile .row {
	margin: 0;
}

.wBlock.offerTile:hover > div {
	border-color: #ff3232;
}

.wBlock.offerTile > div {
	border: 8px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
}

.wBlock.offerTile img {
	max-width: 100%;
	max-height: 150px;
}

.wBlock.offerTile .name a {
	color: #ff3232;
	font-weight: bold;
	text-decoration: underline !important;
}

.wBlock.offerTile .underlined.row.geoRow {
	margin: 20px 0;
}

.wBlock.offerTile .underlined.row.geoRow .geoCaption {
	text-align: center;
}

.wBlock.offerTile .underlined.row.geoRow .geoCaption img.statusIcon {
	display: inline-block;
	margin: 0 5px;
}

.wBlock.offerTile .underlined.row.geoRow:hover div {
	background-color: #ff3232;
	color: #fff !important;
}

.wBlock.offerTile .underlined.row > div {
	border-bottom: solid 1px #ccc;
	padding: 0;
}

.wBlock.offerTile .underlined.row > div.col-sm-4 {
	width: 30%;
	margin-right: 3.33%;
}

.wBlock.offerTile .underlined.row > div .title {
	color: #969696;
	line-height: 30px;
	font-size: 14px;
	word-break: break-all;
	word-break: break-word;
}

.wBlock.offerTile .underlined.row > div .value {
	font-weight: bold;
	color: #4d4d4d;
	line-height: 30px;
	font-size: 14px;
	word-break: break-all;
	word-break: break-word;
}

.wBlock > div {
	background: #fff;
	text-align: justify;
	padding: 5px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.wBlock > div.payment-block {
	text-align: center;
	padding: 10px;
}

.wBlock > div.payment-block:hover {
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	-webkit-transition-duration: 0.12s;
	-moz-transition-duration: 0.12s;
	-ms-transition-duration: 0.12s;
	-o-transition-duration: 0.12s;
	transition-duration: 0.12s;
}

.wBlock > div.payment-block:hover > img.payment-option {
	opacity: 1 !important;
}

.wBlock > div.payment-block > img.payment-option {
	display: block;
	margin: 0 auto;
	padding: 10px;
	-webkit-transition-duration: 0.12s;
	-moz-transition-duration: 0.12s;
	-ms-transition-duration: 0.12s;
	-o-transition-duration: 0.12s;
	transition-duration: 0.12s;
	height: 45px;
}

.wBlock > div.payment-block > img.payment-option.passive {
	opacity: 0.2;
}

.wBlock > div.payment-block > img.payment-option.active {
	opacity: 1;
}

.trackLink {
	margin: 10px 0 0 0;
}

.trackLink > div {
	background: #ff3232;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	color: #fff;
	text-align: justify;
	padding: 10px 0;
}

@media (max-width: 767px) {
	.trackLink {
		padding-left: 15px;
		background: #ff3232;
	}

	.trackLink div {
		margin: 0;
	}
}

.react-grid-Container {
	width: auto !important;
}

.react-grid-Toolbar {
	border: none !important;
}

.react-grid-Grid {
	border: none !important;
}

.react-grid-Canvas {
	overflow: hidden !important;
}

img.miniIcon {
	width: 25px;
	border: solid 1px #ccc;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	margin: 5px 10px;
}

.react-bs-table-pagination span.dropdown {
	display: none;
}

img.statusIcon {
	width: 30px;
	text-align: center;
	display: block;
	margin: 0 auto;
	opacity: 0.7;
	transition-duration: 0.15s;
}

img.statusIcon:hover {
	opacity: 1;
}

.react-bs-table table.table-hover tbody tr:hover {
	background-color: #d2d2d2;
}

.react-bs-table .table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #f1f1f1;
}

/*
.react-bs-table table.table-hover tbody tr:hover td {
    white-space: normal;
    background-color: #ff3232;
    color: #fff;
}

.react-bs-table table.table-hover tbody tr:hover td>img {
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
}
*/
table td,
table th {
	text-align: center;
}

.sposob-item {
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: center;
	line-height: 140px;
	color: #969696;
	font-family: 'Trebuchet MS', sans-serif;
	font-size: 12px;
	font-weight: 400;
}

.sposob-item:hover {
	color: #4d4d4d;
	font-size: 12px;
	font-weight: 400;
}

.sposob-item.active {
	color: #4d4d4d;
	font-size: 12px;
	font-weight: 400;
}

.ru {
	background: url(../images/countries/ru.png) no-repeat 50% 20%;
}

.kz {
	background: url(../images/countries/kz.png) no-repeat 50% 20%;
}

.ua {
	background: url(../images/countries/ua.png) no-repeat 50% 20%;
}

.eu {
	background: url(../images/countries/eu.png) no-repeat 50% 20%;
}

.int {
	background: url(../images/countries/int.png) no-repeat 50% 20%;
}

.visa {
	background: url(../images/img/visa.png) no-repeat 50% 20%;
}

.mc {
	background: url(../images/img/mc.png) no-repeat 50% 20%;
}

.qiwi {
	background: url(../images/img/qiwi.png) no-repeat 50% 20%;
}

.yd {
	background: url(../images/img/yd.png) no-repeat 50% 20%;
}

.wm {
	background: url(../images/img/wm.png) no-repeat 50% 20%;
}

.pp {
	background: url(../images/img/pp.png) no-repeat 50% 20%;
}

.mb {
	background: url(../images/img/mb.png) no-repeat 50% 20%;
}

.visa:hover {
	background: url(../images/img/visah.png) no-repeat 50% 20%;
}

.mc:hover {
	background: url(../images/img/mch.png) no-repeat 50% 20%;
}

.qiwi:hover {
	background: url(../images/img/qiwih.png) no-repeat 50% 20%;
}

.yd:hover {
	background: url(../images/img/ydh.png) no-repeat 50% 20%;
}

.wm:hover {
	background: url(../images/img/wmh.png) no-repeat 50% 20%;
}

.pp:hover {
	background: url(../images/img/pph.png) no-repeat 50% 20%;
}

.mb:hover {
	background: url(../images/img/mbh.png) no-repeat 50% 20%;
}

.visa.active {
	background: url(../images/img/visah.png) no-repeat 50% 20%;
}

.mc.active {
	background: url(../images/img/mch.png) no-repeat 50% 20%;
	display: block;
}

.qiwi.active {
	background: url(../images/img/qiwih.png) no-repeat 50% 20%;
}

.yd.active {
	background: url(../images/img/ydh.png) no-repeat 50% 20%;
}

.wm.active {
	background: url(../images/img/wmh.png) no-repeat 50% 20%;
}

.pp.active {
	background: url(../images/img/pph.png) no-repeat 50% 20%;
}

.mb.active {
	background: url(../images/img/mbh.png) no-repeat 50% 20%;
}

.ticket {
	background-color: #222d32;
	color: #fdfdfd;
	font-size: 18px;
	margin: 5px 0;
	padding: 10px;
	transition-duration: 0.12s;
	cursor: pointer;
}

.ticket > div.unread {
	display: inline-block;
	margin: 5px;
	padding: 0 7px;
	border: solid 1px #ff3232;
	border-radius: 50%;
	background-color: #ff3232;
}

.ticket.notRead {
	opacity: 1;
}

.ticket.read {
	opacity: 0.4;
}

.ticket:hover {
	opacity: 1;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
}

.ticket > span.date {
	float: right;
	font-size: 14px;
	text-align: right;
}

.ticket > span.remove {
	margin: 5px 10px;
	float: right;
	font-size: 20px;
	color: #ff3232;
	text-align: right;
}

button.btn.btn-info {
	background-color: #3b689e;
	background-image: linear-gradient(to bottom, #6285af, #3b689e 100%);
	border-color: #3b689e;
}

button.btn.btn-info:focus,
button.btn.btn-info:hover {
	background-color: #3b689e;
}

button.btn-table-tool {
	min-width: 20px;
	max-width: 100px;
	padding-left: 0px;
	padding-right: 0px;
	background: transparent;
	color: #cc3e4a;
	width: 100%;
	height: 100%;
}

.box .Select {
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

textarea#requestOfferTextArea {
	width: 100%;
	height: 100px;
	border: solid 1px #ccc;
	border-radius: 5px;
	resize: none;
}

.statBlock {
	background: #fff;
	box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.4);
	padding: 15px 0;
}

.blockCaption {
	text-align: center;
	padding: 0 5px 20px 5px;
	color: #ff3232;
	font-weight: 600;
	font-size: 16px;
}

.statNumber {
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	color: #333;
}

.statExplain {
	text-align: center;
	color: #888;
	font-size: 16px;
	font-weight: normal;
}

.statIcon {
	text-align: center;
	font-size: 16px;
}

span.redStat {
	color: #ff3232;
}

img.technicalImage {
	width: 17%;
	position: fixed;
	bottom: 2%;
	right: 3%;
	-moz-animation: popup 2s linear;
	-o-animation: popup 2s linear;
	-webkit-animation: popup 2s linear;
	animation: popup 2s linear;
	opacity: 0.8;
	-webkit-transition-duration: 0.112s;
	-moz-transition-duration: 0.112s;
	-ms-transition-duration: 0.112s;
	-o-transition-duration: 0.112s;
	transition-duration: 0.112s;
	cursor: pointer;
}

img.technicalImage:hover {
	opacity: 1;
}

@-moz-keyframes popup {
	0% {
		bottom: -50px;
		opacity: 0.4;
	}

	100% {
		bottom: 2%;
		opacity: 0.8;
	}
}

@-webkit-keyframes popup {
	0% {
		bottom: -50px;
		opacity: 0.4;
	}

	100% {
		bottom: 2%;
		opacity: 0.8;
	}
}

@-o-keyframes popup {
	0% {
		bottom: -50px;
		opacity: 0.4;
	}

	100% {
		bottom: 2%;
		opacity: 0.8;
	}
}

@-ms-keyframes popup {
	0% {
		bottom: -50px;
		opacity: 0.4;
	}

	100% {
		bottom: 2%;
		opacity: 0.8;
	}
}

@keyframes popup {
	0% {
		bottom: -50px;
		opacity: 0.4;
	}

	100% {
		bottom: 2%;
		opacity: 0.8;
	}
}

i.statusIcon {
	font-size: 20px;
}

i.statusIcon.P {
	color: #f8d347;
}

i.statusIcon.PA {
	color: #999999;
}

i.statusIcon.A {
	color: #99c262;
}

i.statusIcon.D {
	color: #ff3232;
}

tr:hover td i.statusIcon {
	color: #fff;
}

.offerImage img {
	max-width: 100%;
	max-height: 200px;
	background: #fff;
	padding: 5px 7px;
	border-radius: 5px;
}

.offerItemBlock .offerItemOuter {
	background: #fff;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	padding: 10px;
	padding-bottom: 75px;
	display: flex;
	justify-content: center;
}

.offerItemBlock {
	white-space: normal;
	margin: 10px 0;
}

.offerItemBlock i.lockIcon.fa {
	font-size: 24px;
	margin-left: 10px;
}

.offerItemBlock i.lockIcon.fa.fa-lock {
	color: #ff3232;
}

.offerItemBlock i.lockIcon.fa.fa-unlock {
	color: #0c960c;
}

.offerDescriptionRow img {
	max-width: 23px;
	margin: 0 5px;
	border: solid 1px #777;
}

.offerDescriptionRow {
	padding: 5px 0;
	border: solid 1px #fafafa;
}

.offerDescriptionRow:nth-child(even) {
	background-color: #f8fafb;
}

.offerDescriptionRow:nth-child(odd) {
	background-color: #e9edee;
}

/*
.offerItemBlock button.btn.btn-danger {
    right: 25px;
    bottom: 25px;
    position: absolute;
}*/

.offerItemLeft {
	text-align: center;
	align-self: center;
}

.offerDescriptionRow.geoHolder > div {
	text-align: center;
	font-size: 20px;
}

.offerDescriptionRow {
	min-height: 52px !important;
	line-height: 52px;
	/* text-transform: capitalize; */
}

.offerDescriptionRow.geoRow {
	line-height: inherit;
}

.offerDescriptionRow.geoRow .geoName {
	line-height: 54px;
}

.offerDescriptionRow.geoRow > div.col-sm-2 {
	padding-top: 7px;
}

.offerDescriptionRow > .col-xs-4 {
	font-weight: bold;
}

.statBlock {
	min-height: 135px !important;
}

.pageOverlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	max-height: 111vh;
	background: rgba(0, 0, 0, 0.35);
	z-index: 9999;
}

.pageOverlay > i.fa.fa-spinner.css--animate {
	position: absolute;
	top: 50%;
	color: #ff3232;
	left: 50%;
	font-size: 100px;
	margin-left: -25px;
	margin-top: -50px;
}

.content-header h1 {
	margin: 0;
	font-size: 24px;
}

ul.nav.navbar-nav.navbar-right {
	margin-right: 20px;
}

.content-header > .col-xs-2.col-xs-offset-6 {
	text-align: right;
}

h5.deepRequestIcons > i.statusIcon {
	font-size: 32px;
}

@media (max-width: 48em) {
	.geoName {
		padding: 2em 0;
		text-align: center;
		font-size: 18px;
		display: inline-block;
	}
}

.hr-text {
	position: relative;
	text-align: left;
	text-transform: uppercase;
	margin-bottom: 0.5em;
}

.hr-text.hr-text-center {
	text-align: center;
}

.hr-text.hr-text-center > :first-child {
	padding-left: 1em;
}

.hr-text.hr-text-right {
	text-align: right;
}

.hr-text.hr-text-right > :first-child {
	padding-left: 1em;
	padding-right: 0;
}

.hr-text:before {
	position: absolute;
	top: 100%;
	display: block;
	content: '';
	width: 100%;
	height: 1px;
	background-color: #383838;
}

.hr-text > :first-child {
	position: relative;
	z-index: 0;
	display: inline-block;
	padding-right: 1em;
	vertical-align: middle;
}

.equal,
.equal > div[class*='col-'] {
	display: flex;
	flex-wrap: wrap;
}

.equal > div[class*='col-'] > div {
	width: 100%;
}

.landing > div[class*='col-'] {
	padding: 10px;
}

.landing .thumbnail {
	width: 100%;
	white-space: normal;
	word-wrap: break-word;
	display: flex;
	flex-direction: column;
	padding: 0px;
	margin: 0px;
}

.landing .thumbnail .caption {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: stretch;
	height: 100%;
	padding: 0px;
}

.landing .thumbnail .caption > :first-child {
	padding: 9px;
}

.landing .thumbnail .caption > :last-child {
	border-radius: 0px;
}

.landing .thumbnail .caption > :first-child > :first-child {
	margin-top: 0px;
}

.landing .thumbnail .caption > :first-child > :last-child {
	margin-bottom: 0px;
}

td.clickableTable {
	cursor: url('../images/elements/cursor-editor.png') 0 25, pointer;
}

/* img.payment-system {
     filter: invert(90%);
} */

/*
tr:hover td img.payment-system {
    filter: invert(3%);
    background-color: transparent !important;
    border-radius: initial !important;
}
*/

.box .box {
	margin-bottom: 0px;
}

.has-error .Select {
	border-color: #dd4b39;
}

.has-success .Select {
	border-color: #00a65a;
}

.has-warning .Select {
	border-color: #8a6d3b;
}

.content-wrapper {
	position: relative;
}

.hideOverlay {
	display: none;
}

.tabs .nav-tabs > li > a {
	border: 3px solid transparent;
}

.tabs .nav-tabs {
	border-bottom: 3px solid;
}

.tabs .nav-tabs > li {
	margin-bottom: -3px;
}

.tabs-primary .nav-tabs {
	border-bottom-color: #3c8dbc;
}

.tabs-primary .nav-tabs > li.active > a,
.tabs-primary .nav-tabs > li.active > a:focus,
.tabs-primary .nav-tabs > li.active > a:hover {
	border: 3px solid;
	border-color: #3c8dbc #3c8dbc transparent #3c8dbc;
}

.tabs-primary .nav-tabs > li > a:hover {
	border: 3px solid #3c8dbc;
}

.expanded-block {
	position: relative;
	transition: 0.5s ease-in-out;
	animation: expand 1s;
}

.no-padding-top-td {
	margin-top: -8px;
}

.no-padding-bottom-td {
	margin-bottom: -8px;
}

.no-padding-td {
	margin: -8px;
}

.custom-tab-title {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin: 0px;
	text-align: center;
}

.custom-tab-title > h4 {
	margin: 0px;
}

.custom-tab-title > :first-child {
	margin-bottom: 10px;
}

.custom-tab-title:after {
	content: '';
	display: inline-block;
	height: 100%;
}

.offer-access {
	background-color: #fff;
	color: #333;
	text-align: left;
	white-space: normal;
	border-radius: 0;
}

.clip {
	white-space: nowrap;
	/* Запрещаем перенос строк */
	overflow: hidden;
	/* Обрезаем все, что не помещается в область */
	text-overflow: ellipsis;
	/* Добавляем многоточие */
}

.pager a {
	background: #fafafa;
	color: #666;
	border: 1px solid #ddd;
	padding: 5px 20px;
}

.pager .active-page a {
	background-color: #337ab7 !important;
	color: #fff;
	border-color: #337ab7;
	cursor: default;
}

.offer-item {
	padding: 0px 10px;
}

.offer-item .detail {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
	padding: 0px;
	width: 100%;
	white-space: normal;
	word-wrap: break-word;
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;
	margin-bottom: 20px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	transition: border 0.2s ease-in-out;
	cursor: pointer;
}

.offer-item .active {
	border-color: #337ab7;
	background-color: #f9f9f9;
	-webkit-box-shadow: 0 0 14px rgba(51, 122, 183, 0.9);
	-moz-box-shadow: 0 0 14px rgba(51, 122, 183, 0.9);
	box-shadow: 0 0 14px rgba(51, 122, 183, 0.9);
}

.offer-item .thumbnail {
	padding: 0px;
	margin-bottom: 0px;
	border: 0px;
}

.offer-item .caption {
	display: flex;
	background-color: inherit;
	flex-direction: column;
	justify-content: flex-end;
	align-content: stretch;
	height: 100%;
	padding: 0px;
}

.offer-item h4 {
	padding: 0px 5px;
}

.offer-item .thumbnail img {
	border: 1px;
	border-radius: 4px;
	max-height: 260px;
	-webkit-box-shadow: 0 0 5px rgba(176, 176, 176, 0.3);
	-moz-box-shadow: 0 0 5px rgba(176, 176, 176, 0.3);
	box-shadow: 0 0 5px rgba(176, 176, 176, 0.3);
}

.outer-settings {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 10px;
	background-color: #fff;
	width: 200px;
	position: fixed;
	right: -200px;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.5s;
	z-index: 1040;
}

.outer-settings.active {
	right: 0;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.outer-settings > .action-toggle {
	position: absolute;
	top: 50%;
	transform: translateY(calc(-50% - 54px));
	width: 35px;
	left: 0px;
	min-width: 35px;
	transition: all 0.5s;
}

.outer-settings > .action-toggle.active-toggle {
	left: -35px;
}

.outer-settings .list-item {
	padding: 5px;
	display: block;
	border: 1px solid #ddd;
}

@media (max-width: 1368px) {
	.offer-item .thumbnail img {
		max-height: 240px;
	}
}

@media (max-width: 1200px) {
	.offer-item .thumbnail img {
		max-height: 230px;
	}
}

@media (max-width: 992px) {
	.offer-item .thumbnail img {
		max-height: 210px;
	}
}

@media (max-width: 768px) {
	.offer-item .thumbnail img {
		max-height: 190px;
	}
}

@media (max-width: 480px) {
	.offer-item .thumbnail img {
		max-height: 170px;
	}
}

.user-offer-item {
	border-radius: 3px;
	border: 1px solid #ddd;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
	margin: 0px -8px 10px -8px;
	padding: 15px 5px;
}

.landings-list {
	padding: 0px 20px 20px 20px !important;
}

.user-offer-item.active-item {
	border-color: #337ab7;
	-webkit-box-shadow: 0 0 5px rgba(51, 122, 183, 0.9);
	-moz-box-shadow: 0 0 5px rgba(51, 122, 183, 0.9);
	box-shadow: 0 0 5px rgba(51, 122, 183, 0.9);
}

.user-offer-item .thumbnail {
	margin-bottom: 10px;
	padding: 0px;
	margin-bottom: 10px;
}

.user-offer-item .payout {
	overflow: auto;
	max-height: 300px;
}

.user-offer-item div[class*='col-'] {
	padding: 0px 8px;
}

.user-offer-item div[class*='row'] {
	margin-right: -8px;
	margin-left: -8px;
}

.divider.divider-center:before,
.divider.divider-right:before,
.divider:after {
	content: ' ';
}

.divider {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.divider:after,
.divider:before {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	border-top: 1px solid #383838;
}

.divider > :first-child {
	padding-left: 0;
	padding-right: 1rem;
	text-align: left;
	color: #5c5c5c;
	text-transform: uppercase;
}

.divider.divider-center > :first-child {
	text-align: center;
	padding-left: 1rem;
}

.divider.divider-right:after {
	content: none;
}

.divider.divider-right > :first-child {
	text-align: right;
	padding-left: 1rem;
	padding-right: 0;
}

.btn-box-tool-update {
	border: none;
	margin: -5px 0px -5px 5px;
	transition: opacity 0.5s;
	width: 0px;
	background: transparent;
	color: #97a0b3;
	opacity: 0;
}

.btn-box-tool-update:hover {
	color: #606c84;
}

.btn-box-tool-update.btn:active {
	box-shadow: none;
}

.collapsed-box .btn-box-tool-update {
	opacity: 1;
	width: auto;
	background-color: #367fa9 !important;
}

.collapsed-box .btn-box-tool-update:hover {
	background-color: #286090 !important;
}

.chat-message-image {
	display: inline;
	position: relative;
}

.chat-message-image.attach {
	display: inline-block;
	position: relative;
}

.chat-message-image > * {
	transition: all 0.35s;
}

.chat-message-image > img {
	padding: 2px;
	cursor: zoom-in;
}

.chat-message-image > img:hover {
	transform: scale(1.07);
}

.chat-message-img {
	cursor: default !important;
	position: fixed;
	max-height: 80vh;
	max-width: 80vw;
	z-index: 100001;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 0px;
	opacity: 0;
}

.chat-message-img-open {
	width: auto;
	opacity: 1;
}
.chat-message-img:hover {
	transform: none !important;
}

.overlayd-page {
	top: 0;
	left: 0;
	height: 111vh;
	width: 100vw;
	display: block;
	position: fixed;
	background: rgba(34, 45, 50, 0.5);
	z-index: 100000;
}

.overlayd-page .closed-button {
	position: fixed;
	right: 10vw;
	top: 10vh;
	width: 5vw;
	cursor: pointer;
}

.chat-message-img-closed-button {
	position: absolute;
	cursor: pointer;
	top: 0px;
	right: 2px;
	z-index: 99999;
}

#root section.finance-content {
	height: calc(111vh - 70px);
	overflow: hidden;
	margin: 0px;
}

.payments-container {
	display: flex;
	height: 100%;
	flex-direction: column;
	min-height: stretch;
}

.payments-container > * {
	display: block;
}

.payments-box {
	position: relative;
	flex-grow: 1;
	display: block;
}

.payments-wrapper {
	height: 100%;
}

.payments-wrapper > * {
	height: 100%;
}

.payments-wrapper .tab-content {
	max-height: calc(100% - 46px);
	margin-bottom: -46px;
}

.payments-box-body {
	margin-top: 20px;
	white-space: nowrap;
	margin-bottom: 0px;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 8px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.payments-list {
	width: 272px;
	margin: 0 4px;
	height: 100%;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
}

.payment-item {
	background-color: #fff;
	margin: 10px 5px;
	overflow: hidden;
}

.payment-item .dl-horizontal dd {
	margin-left: 0px;
}

.payment-item .dl-horizontal dt {
	float: none;
	width: auto;
	overflow: hidden;
	text-align: inherit;
	text-overflow: ellipsis;
}

.payment-item.alert-warning {
	color: #8a6d3b !important;
}

.payment-item.alert-success {
	color: #3c763d !important;
}
.payment-item.alert-danger {
	color: #a94442 !important;
}

.payment-item.alert-info {
	color: #31708f !important;
}

.payment-item.alert-success a,
.payment-item.alert-warning a,
.payment-item.alert-danger a,
.payment-item.alert-info a {
	color: #3c8dbc !important;
}

.custom-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
	width: 12px;
	background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #888;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.text-button {
	cursor: pointer;
	margin-left: 5px;
}

.text-button > * {
	color: #337ab7;
	transition: all 0.8s ease-in-out;
}

.text-button:hover > * {
	color: #00a65a;
	transform: rotate(540deg);
}

.site-link {
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}

.user-landing-item {
	padding: 0px 10px;
}

.user-landing-item .landings-stats {
	display: flex;
	margin-bottom: 10px;
	flex-wrap: wrap;
}
.user-landing-item .landings-stats h3 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.user-landing-item > div {
	border-radius: 4px;
	margin: 0px 0px 20px 0px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.user-landing-item-detail .thumbnail {
	border: none;
	margin-bottom: 0;
}

.user-landing-item-detail {
	border: 2px solid;
	border-color: transparent;
	border-radius: 4px;
	transition: all 0.5s;
	position: relative;
	cursor: pointer;
	min-height: 100px;
}

.user-landing-item-detail h1:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.user-landing-item-detail .overlay-block {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.75);
	left: 0;
	top: 0;
	opacity: 0;
	transition: opacity 0.4s;
}

.user-landing-item-detail:hover .overlay-block {
	opacity: 1;
}

.user-landing-item-detail h1 {
	text-transform: uppercase;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	transition: opacity 0.5s;
	vertical-align: middle;
	text-align: center;
}

.user-landing-item-detail:hover {
	border-color: #337ab7;
}

.user-landing-item-detail:hover h1 {
	opacity: 1;
}

.custom-list {
	margin-left: -10px;
	margin-right: -10px;
	line-height: 32px;
	min-height: 32px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.text-ellipsis {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.custom-list:nth-child(even) {
	background-color: #f8fafb;
}

.custom-list:nth-child(odd) {
	background-color: #e9edee;
}

.access-info {
	color: #31708f !important;
	padding: 10px;
	padding-right: 35px;
	margin: 0px;
}

html body button {
	outline: none !important;
}

@keyframes expand {
	from {
		max-height: 0px;
	}

	to {
		max-height: 1000px;
	}
}
