
.languageBox {
	background-color: #222d32;
	min-height: 70px;
}

.languageBox ul {
	background-color: #222d32;
	border: 1px solid #1e282c;
}

.languageBox a {
    cursor: pointer !important;
	background-color: #222d32 !important;
	background-image: none !important;
}

.languageBox a:hover {
	background-color: #1e282c !important;
}