 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: none;
  width: 1em;
  text-align: center;
  text-transform: none;
  line-height: 1em;
}
[class^="icon-nav"]:before, [class*=" icon-nav"]:before {
  font-family: "navigation";
}

.icon-nav-dashboard:before {content: '\e000';}
.icon-nav-offers:before {content: '\e001';}
.icon-nav-daily:before {content: '\e002';}
.icon-nav-common:before {content: '\e003';}
.icon-nav-request:before {content: '\e004';}
.icon-nav-subid:before {content: '\e005';}
.icon-nav-payments:before {content: '\e006';}
.icon-nav-user:before {content: '\e007';}
.icon-nav-settings:before {content: '\e008';}
.icon-nav-support:before {content: '\e009';}

.icon-edit:before {content: '\e800';}
.icon-delete:before {content: '\e801';}
.icon-cancel:before {content: '\e802';}
.icon-cancel-circled:before {content: '\e803';}
.icon-logout:before {content: '\e804';}
.icon-settings:before {content: '\e805';}
.icon-settings-one:before {content: '\e820';}
.icon-useradd:before {content: '\e806';}
.icon-offer:before {content: '\e807';}
.icon-ok:before {content: '\e808';}
.icon-ok-circled:before {content: '\e809';}
.icon-plus:before {content: '\e80a';}
.icon-plus-circled:before {content: '\e80b';}
.icon-offer-flag:before {content: '\e80c';}
.icon-like-alt:before {content: '\e80d';}
.icon-dislike-alt:before {content: '\e80e';}
.icon-like:before {content: '\e80f';}
.icon-dislike:before {content: '\e810';}
.icon-link:before {content: '\e811';}
.icon-attention-alt:before {content: '\e812';}
.icon-attention:before {content: '\e813';}
.icon-menu:before {content: '\e814';}
.icon-hide:before {content: '\e815';}
.icon-show:before {content: '\e816';}
.icon-show-grid:before {content: '\e817';}
.icon-show-list:before {content: '\e818';}
.icon-search:before {content: '\e819';}
.icon-sort:before {content: '\e81a';}
.icon-sort-down:before {content: '\e81b';}
.icon-sort-up:before {content: '\e81c';}
.icon-date:before {content: '\e81d';}
.icon-rub:before {content: '\e81e';}
.icon-loading:before {content: '\e81f';}
/*
   Animation example, for spinners
*/
.css--animate {
  -moz-animation: spin 3s infinite linear;
  -o-animation: spin 3s infinite linear;
  -webkit-animation: spin 3s infinite linear;
  animation: spin 3s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
